import { Table, Button } from "antd";
import { useHistory } from "react-router-dom";
import { EditTwoTone, DownCircleTwoTone, DownloadOutlined } from "@ant-design/icons";
import moment from "moment";

const Admin = ({isLoading, isError, data, onSubmit, onSubmitAll, date}) => {
    const history = useHistory();

    if (isLoading)
        return (<div>Loading data ...</div>)

    if (isError)
        return (<div>Error Loading data. Please try again</div>)

    
    if (data.length == 0) {
        return (<div>No data</div>)
    }

    const columns = [
        {
            title: '#',
            key: 'index',
            align: 'center',
            render: (text, record, index) => index+1,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        }, 
        // {
        //     title: 'Date',
        //     dataIndex: 'date',
        //     key: 'date',
        //     render: (text, record) => JSON.stringify(record)
        // }, 
        {
            title: 'Doctors',
            dataIndex: 'doctors_db',
            key: 'doctors_db',
            align: 'center',
            render: (text, record) => {
                console.log(record);
                let exist = false;
                if (Array.isArray(record.reviewed)) {
                    const obj = record.reviewed.find(item => item.occupation === 'doctor')
        
                    if (!obj) {
                        exist = false;
                    }
                    else if (moment(obj.date).isSameOrAfter(date, 'month'))
                        exist = true;
                }
                
                return ( 
                <div>
                    <EditTwoTone twoToneColor={exist ? "green" : "red"} onClick={ () => window.open(`/employees/doctor/${record._id}`, 'blank') }/>&nbsp;&nbsp;
                </div> )
            }
        },        
        {
            title: 'Admins',
            dataIndex: 'admins',
            key: 'admins',
            align: 'center',
            render: (text, record) => {
                let exist = false;
                for ( let i=0; i<record.data.length; i++ ) {
                    if (record.data[i].name === 'admins') {
                        exist = true;
                        break;
                    }
                }
                
                return ( 
                <div>
                    <EditTwoTone twoToneColor={exist ? "green" : "red"} onClick={ () => history.push(`/staff/dynamic/admins/${record._id}`) }/>&nbsp;&nbsp;
                    <DownCircleTwoTone twoToneColor={exist ? "green" : "red"} onClick={ () => onSubmit('admins', record._id)} />
                </div> )
            }
        },
        {
            title: 'Other doctors',
            dataIndex: 'doctors',
            key: 'doctors',
            align: 'center',
            render: (text, record) => {
                let exist = false;
                for ( let i=0; i<record.data.length; i++ ) {
                    if (record.data[i].name === 'doctors') {
                        exist = true;
                        break;
                    }
                }
                
                return ( 
                <div>
                    <EditTwoTone twoToneColor={exist ? "green" : "red"} onClick={ () => history.push(`/staff/dynamic/doctors/${record._id}`) }/>&nbsp;&nbsp;
                    <DownCircleTwoTone twoToneColor={exist ? "green" : "red"} onClick={ () => onSubmit('doctors', record._id)} />
                </div> )
            }
        },
        {
            title: 'Nurses',
            dataIndex: 'nurses',
            key: 'nurses',
            align: 'center',
            render: (text, record) => {
                console.log(record);
                let exist = false;
                if (Array.isArray(record.reviewed)) {
                    const obj = record.reviewed.find(item => item.occupation === 'nurse')
        
                    if (!obj) {
                        exist = false;
                    }
                    else if (moment(obj.date).isSameOrAfter(date, 'month'))
                        exist = true;
                }
                
                return ( 
                <div>
                    <EditTwoTone twoToneColor={exist ? "green" : "red"} onClick={ () => window.open(`/employees/nurse/${record._id}`, 'blank') }/>&nbsp;&nbsp;
                </div> )
            }
        },
        {
            title: 'Pharmacist',
            dataIndex: 'pharmacist',
            key: 'pharmacist',
            align: 'center',
            render: (text, record) => {
                let exist = false;
                if (Array.isArray(record.reviewed)) {
                    const obj = record.reviewed.find(item => item.occupation === 'pharmacist')
        
                    if (!obj) {
                        exist = false;
                    }
                    else if (moment(obj.date).isSameOrAfter(date, 'month'))
                        exist = true;
                }
                
                return ( 
                <div>
                    <EditTwoTone twoToneColor={exist ? "green" : "red"} onClick={ () => window.open(`/employees/pharmacist/${record._id}`, 'blank') }/>&nbsp;&nbsp;
                </div> )
            }
        },
        {
            title: 'Technicians',
            dataIndex: 'technicians',
            key: 'technicians',
            align: 'center',
            render: (text, record) => {
                let exist = false;
                for ( let i=0; i<record.data.length; i++ ) {
                    if (record.data[i].name === 'technicians') {
                        exist = true;
                        break;
                    }
                }
                
                return ( 
                <div>
                    <EditTwoTone twoToneColor={exist ? "green" : "red"} onClick={ () => history.push(`/staff/dynamic/technicians/${record._id}`) }/>&nbsp;&nbsp;
                    <DownCircleTwoTone twoToneColor={exist ? "green" : "red"} onClick={ () => onSubmit('technicians', record._id)} />
                </div> )
            }
        },
        {
            title: 'Services',
            dataIndex: 'services',
            key: 'services',
            align: 'center',
            render: (text, record) => {
                let exist = false;
                for ( let i=0; i<record.data.length; i++ ) {
                    if (record.data[i].name === 'services') {
                        exist = true;
                        break;
                    }
                }
                
                return ( 
                <div>
                    <EditTwoTone twoToneColor={exist ? "green" : "red"} onClick={ () => history.push(`/staff/dynamic/services/${record._id}`) }/>&nbsp;&nbsp;
                    <DownCircleTwoTone twoToneColor={exist ? "green" : "red"} onClick={ () => onSubmit('services', record._id)} />
                </div> )
            }
        }
      ];

      const footer = () => {
          return (
              <center>
                    <Button type="primary" shape="round" icon={<DownloadOutlined />} onClick={() => onSubmitAll('admins')} size="middle">Admins</Button>&nbsp;
                    <Button type="primary" shape="round" icon={<DownloadOutlined />} onClick={() => onSubmitAll('doctors')} size="middle">Doctors</Button>&nbsp;
                    <Button type="primary" shape="round" icon={<DownloadOutlined />} onClick={() => onSubmitAll('nurses')} size="middle">Nurses</Button>&nbsp;
                    <Button type="primary" shape="round" icon={<DownloadOutlined />} onClick={() => onSubmitAll('pharmacist')} size="middle">Pharmacist</Button>&nbsp;
                    <Button type="primary" shape="round" icon={<DownloadOutlined />} onClick={() => onSubmitAll('technicians')} size="middle">Technicians</Button>&nbsp;
                    <Button type="primary" shape="round" icon={<DownloadOutlined />} onClick={() => onSubmitAll('services')} size="middle">Services</Button>&nbsp;
              </center>
          )
      }

    return (<Table columns={columns} dataSource={data} pagination={{ pageSize: 200}} size="middle" footer={footer} />);
}

export default Admin;