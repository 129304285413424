import React, { useEffect } from 'react';
import Card from '../../components/card';
import { useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import HeaderComponent from '../../components/header';

export default function Staff() {
    const userReducer = useSelector(state => state.userReducer);
    const history = useHistory();

    if (!userReducer.loggedIn)
        return <Link to="/login">Login</Link>

    return (
    <div className="container">
        <HeaderComponent />
        <br />

        <div className="row">
            <Card title="Doctors (الاطباء)" content="View & Update the doctors stat" button="Edit" onClick={() => userReducer.user._id && history.push(`/employees/doctor/${userReducer.user._id}`)} />
            <Card title="Administrators (الاداريين)" content="View & Update the administrators stat" button="Edit" onClick={() => userReducer.user._id && history.push(`/staff/dynamic/admins/${userReducer.user._id}`)} /> 
            <Card title="Pharmacist (الصيدليين)" content="View & Update the pharmacists stat" button="Edit" onClick={() => userReducer.user._id && history.push(`/employees/pharmacist/${userReducer.user._id}`)} />
            <Card title="Technicians (الفنيين)" content="View & Update the technicians stat" button="Edit" onClick={() => userReducer.user._id && history.push(`/staff/dynamic/technicians/${userReducer.user._id}`)} />
            <Card title="Nurses (التمريض)" content="View & Update the nurses stat" button="Edit" onClick={() => userReducer.user._id && history.push(`/employees/nurse/${userReducer.user._id}`)} />
            <Card title="Others (including dental & maternity) (اسنان وأمومة)" content="View & Update the others stat" button="Edit" onClick={() => userReducer.user._id && history.push(`/staff/dynamic/doctors/${userReducer.user._id}`)} />
            <Card title="Services provided (الخدمات المقدمة)" content="View & Update the services offered in your center" button="Edit" onClick={() => userReducer.user._id && history.push(`/staff/dynamic/services/${userReducer.user._id}`)} />
            <Card title="Patient Survey Code" content="Get the patient survey code" button="Edit" onClick={() => userReducer.user._id && history.push(`/staff/survey`)} />
            {/* <Card title="Other employees statistics (احصائيات الموظفين الآخرين)" content="to be updated once a year" button="Edit" onClick={() => userReducer.user._id && history.push(`/staff/otheremployees`)} /> */}
            {/* <Card title="Staff Survey Code" content="Get the staff survey code" button="Edit" onClick={() => userReducer.user._id && history.push(`/staff/staff_survey`)} /> */}
        </div>
    </div>
    );
}