import { useSelector } from "react-redux";
import { useEffect, useState } from 'react';
import AdminEmployeesComponent from '../../components/admin-employees';
import service from '../../config/service';
import { Select, Button, Input, Form, Spin } from 'antd';
import HeaderComponent from '../../components/header';

const { Option } = Select;

const AdminEmployeesPage = () => {
    const userReducer = useSelector(state => state.userReducer);

    const [healthCenters, setHealthCenters] = useState([]);
    const [values, setValues] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [clinics, setClinics] = useState([]);
    const [certificates, setCertificates] = useState([]);
    const [nonCompletedEntries, setCompletedEntries] = useState([]);

    useEffect( () => {
        service.getRestClient().get('/employees/clinics/doctor').then(res => setClinics(res.data.map(clinic => ({label: clinic.name, value: clinic.name}))))
        service.getRestClient().get('/employees/certificates/doctor').then(res => setCertificates(res.data.map(certificate => ({label: certificate, value: certificate}))))
    }, [])


    if (userReducer.user.role !== 'admin' && userReducer.user.role !== 'stats') {
        return (<div>Needs access permission</div>)
    }

    const onSubmit = (v) => {
        //alert(JSON.stringify(v))

        setValues(v);
        setSubmitted(true);
    }

    const onHealthAreaChange = async (value) => {
        try {
            const res = await service.getRestClient().get(`/cdphc/healthCenters/by/area/${value}/date`);
            const options = res.data.map( center => ({label: center.name, value: center._id}) )
            setHealthCenters(options);
        }
        catch(e) {
            console.log(e);
            alert('Error occured while fetching health centers: ' + e.message);
        }
        finally {
            return values;
        }
    }

    return (
        <div>
            <HeaderComponent />
            <br />
            <Form layout="inline" initialValues={{'occupation': 'doctor'}} onFinish={onSubmit}>
                <Form.Item name="healthArea">
                    <Select
                        style={{ width: 200 }}
                        showSearch
                        placeholder="Select health area"
                        optionFilterProp="children"
                        allowClear
                        onChange={onHealthAreaChange}
                    >
                        <Option value="Capital Health Area">Capital Health Area</Option>
                        <Option value="Hawalli Health Area">Hawalli Health Area</Option>
                        <Option value="Farwaniya Health Area">Farwaniya Health Area</Option>
                        <Option value="Mubarak AlKabeer Health Area">Mubarak Al Kabeer Health Area</Option>
                        <Option value="Ahmadi Health Area">Ahmadi Health Area</Option>
                        <Option value="Jahra Health Area">Jahra Health Area</Option>
                        <Option value="All">All</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="center_id">
                    <Select
                        style={{ width: 200 }}
                        placeholder="Select Health Center"
                        showSearch
                        optionFilterProp="children"
                        allowClear
                    >
                    {
                        healthCenters.map(item => (
                            <Option value={item.value} key={item.value}>
                                {item.label}
                            </Option>
                        ))
                    }
                    </Select>
                </Form.Item>
                <Form.Item name="occupation">
                    <Select
                        style={{ width: 200 }}
                        placeholder="Select Occupation"
                        optionFilterProp="children"
                        allowClear
                        defaultActiveFirstOption={true}
                        defaultValue={'Doctor'}
                    >
                        <Option value="doctor">Doctor</Option>
                        <Option value="nurse">Nurse</Option>
                        <Option value="technician">Technician</Option>
                        <Option value="pharmacist">Pharmacist</Option>
                        <Option value="lab">Lab</Option>
                        <Option value="xray">XRay</Option>
                        <Option value="maternity">Maternity</Option>
                        <Option value="dental">Dental</Option>
                        <Option value="porters">Porters</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="hod">
                    <Select
                        style={{ width: 200 }}
                        placeholder="Head of center"
                        optionFilterProp="children"
                        allowClear
                    >
                        <Option value="yes">Yes</Option>
                        <Option value="no">No</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="fileNo">
                    <Input placeholder="fileNo" allowClear style={{ width: 200}}  />
                </Form.Item>
                <Form.Item name="email">
                    <Input placeholder="Email" allowClear style={{ width: 200}} />
                </Form.Item>
                <Form.Item name="mobile">
                    <Input placeholder="Mobile" allowClear style={{ width: 200}} />
                </Form.Item>
                <Form.Item name="name">
                    <Input placeholder="Name" allowClear style={{ width: 200}} />
                </Form.Item>
                <Form.Item name="retired">
                    <Select 
                        style={{ width: 200 }}
                        placeholder="Retired"
                        optionFilterProp="children"
                        allowClear
                    >
                        <Option value="retired">Retired only</Option>
                        <Option value="not_retired">Not retired only</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="nationality">
                    <Select 
                        style={{ width: 200 }}
                        placeholder="Nationality"
                        optionFilterProp="children"
                        allowClear
                    >
                        <Option value="kuwait">Kuwaiti</Option>
                        <Option value="non-kuwait">Non Kuwaiti</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="jobTitle">
                    <Select 
                        mode="tags"
                        style={{ width: 200 }}
                        placeholder="Job Title"
                        optionFilterProp="children"
                        allowClear
                    >
                        <Option value="Consultant">Consultant</Option>
                        <Option value="Senior Specialist">Senior Specialist</Option>
                        <Option value="Specialist">Specialist</Option>
                        <Option value="Senior Registrar">Senior Registrar</Option>
                        <Option value="Registrar">Registrar</Option>
                        <Option value="Assistant Registrar">Assistant Registrar</Option>
                        <Option value="GP - A">GP - A</Option>
                        <Option value="GP - B">GP - B</Option>
                        <Option value="GP">GP</Option>
                        <Option value="Locum">Locum</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="clinics">
                    <Select
                        mode="multiple"
                        style={{ width: 200 }}
                        placeholder="Clinics"
                        showSearch
                        optionFilterProp="children"
                        allowClear
                    >
                    {
                        clinics.map(item => (
                            <Option value={item.value} key={item.value}>
                                {item.label}
                            </Option>
                        ))
                    }
                    </Select>
                </Form.Item>
                <Form.Item name="certificates">
                    <Select
                        mode="multiple"
                        style={{ width: 200 }}
                        placeholder="certificates"
                        showSearch
                        optionFilterProp="children"
                        allowClear
                    >
                    {
                        certificates.map(item => (
                            <Option value={item.value} key={item.value}>
                                {item.label}
                            </Option>
                        ))
                    }
                    </Select>
                </Form.Item>
                <Form.Item name="cid">
                    <Input placeholder="cid" allowClear style={{ width: 200}} />
                </Form.Item>
                <Button onClick={() => window.open("/updateSecretForm", "_blank")}>Add</Button>&nbsp;
                <Button htmlType="submit" type="primary">Submit</Button>
            </Form>   
            <br /><br />
            <Spin spinning={submitted}><AdminEmployeesComponent values={values} submitted={submitted} setSubmitted={setSubmitted} secret setCompletedEntries={setCompletedEntries} /></Spin>
        </div>
    )

}

export default AdminEmployeesPage;